<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--15"
      data-black-overlay="6"
      :style="{ backgroundImage: 'url(' + src + ')' }"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">Entre em contato conosco</h2>
              <!-- <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text.
              </p> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Contact Address Area  -->
    <div class="rn-contact-top-area ptb--120 bg_color--5">
      <v-container>
        <div class="text-center section-title mb--30">
          <span class="subtitle">Contato</span>
          <!-- <h2 class="heading-title">Nossas informações de contato</h2> -->
          <!-- <p class="description">
            There are many variations of passages of Lorem Ipsum available,
            <br />
            but the majority have suffered alteration.
          </p> -->
        </div>
        <ContactAddress />
      </v-container>
    </div>
    <!-- End Contact Address Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../components/Header";
  import ContactAddress from "../components/contact/ContactAddress";
  import Footer from "../components/Footer";
  export default {
    components: { Header, ContactAddress, Footer },
    data() {
      return {
        logo: require("../assets/images/logo-sm.png"),
        src: require("../assets/images/contact/contact.jpeg"),
      };
    },
  };
</script>

<style lang="scss">
.subtitle {
  font-size: 24px !important;
}
  .mapouter {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 800px;
    width: 100%;
    @media only screen and (max-width: 1199px) {
      height: 500px;
    }
    @media only screen and (max-width: 992px) {
      height: 420px;
    }
  }
  .gmap_canvas iframe {
    width: 100%;
    height: 100%;
  }
</style>
